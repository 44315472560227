html {
	width: 100%;
	height: 100%;
	-webkit-tap-highlight-color: transparent;
	background: #000D1B;
	overflow-x: hidden;
}

body {
	margin: 0;
	font-family: 'inter';
	/* -webkit-font-smoothing: antialiased; */
	/* -moz-osx-font-smoothing: grayscale; */
	background: #000D1B !important;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

:root {
	--transition-time: 350ms;
}

.page-root {
	position: absolute;
	inset: 0;
	overflow-y: auto;
	overflow-x: hidden;
	transform-origin: center;
}

.slide-route-transition,
.route-transition {
	position: relative;
	z-index: 4;
	background: #000D1B;
	min-height: 100vh;
}

.page-root::-webkit-scrollbar {
	display: none;
}

.root-transition {
	overflow-x: hidden;
	transition-property: transform, opacity;
	transition-timing-function: ease-in-out;
	transition-delay: 0;
	transition-duration: 400ms;
	position: relative;
	z-index: 3;
}

.fast-fade {
	transition-property: opacity;
	transition-duration: 300ms;
}

@keyframes genie-in {
	0% {
		transform: translateY(100%) scale(0);
		opacity: 0;
	}

	100% {
		transform: translateY(0) scale(1);
		opacity: 1;
	}
}

.slide-exit-active .hide-on-slide {
	opacity: 0;
}

.hide-on-slide {
	transition-property: opacity;
	transition-duration: 300ms;
}

.slide-enter,
.slide-exit,
.slide-enter-active,
.slide-exit-active {
	position: absolute;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	transition-property: transform, opacity;
	transition-timing-function: ease-in-out;
	transition-duration: 320ms;
	transition-delay: 0;
	z-index: 1;
}

/* Default z-index for all transitions */
.route-transition.slide-exit,
.route-transition.slide-exit-active {
	z-index: 3;
}

.slide-exit,
.slide-exit-active {
	z-index: 1;
}

.slide-enter,
.slide-enter-active {
	z-index: 2;
}


/* Slide Forward */
.slide-enter {
	transition-property: none;
	transform: translateX(100%);
}

.slide-enter-active {
	transform: translateX(0);
}

.slide-exit {
	transform: translateX(0);
	opacity: 1;
}

.slide-exit-active {
	transform: translateX(-30%);
	opacity: 1;
}


/* Slide backward */
.back.slide-enter {
	transform: translateX(-30%);
}

.back.slide-enter-active {
	transform: translateX(0);
}

.back.slide-exit {
	opacity: 1;
	transform: translateX(0);
}

.back.slide-exit,
.back.slide-exit-active {
	transform: translateX(100%);
	transition-duration: 550ms;
	opacity: 1;
	z-index: 16;
	background: #000D1B !important;
}

.forward.slide-enter,
.forward.slide-enter-active {
	z-index: 16;
}

/* Slide Forward */
.dissolve-enter {
	opacity: 0;
}

.dissolve-enter-active {
	opacity: 1;
}

.dissolve-exit {
	opacity: 1;
}

.dissolve-exit-active {
	opacity: 0;
}

/* dissolve backward */
.back.dissolve-enter {
	opacity: 1;
}

.back.dissolve-enter-active {
	opacity: 0;
}

.back.dissolve-exit {
	opacity: 0;
}

.back.dissolve-exit-active {
	opacity: 1;
}

.slide-exit .snap-transition,
.slide-exit-active .snap-transition,
.dissolve-exit .snap-transition,
.dissolve-exit-active .snap-transition {
	opacity: 0;
}



.user-select-none {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a {
	text-decoration: none;
}

.mySpan {
	background: -webkit-linear-gradient(#eee, #333);
	background-clip: text;
	-webkit-text-fill-color: transparent;
}


.switchContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 8px;
	padding-bottom: 8px;
}

.toggle-switch {
	position: relative;
	width: 52px;
	display: inline-block;
	text-align: left;
}

.checkbox {
	display: none;
}

.label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 0 solid #C3CCD5;
	border-radius: 20px;
}

.inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: 0.2s;
}

.inner:before,
.inner:after {
	float: left;
	width: 50%;
	height: 32px;
	padding: 0;
	line-height: 32px;
	color: #C3CCD5;
	font-weight: bold;
	box-sizing: border-box;
}

.inner:before {
	content: " ";
	/* padding-left: -10px; */
	background-color: #1BB6B6;
	color: #fff;
}

.inner:after {
	content: " ";
	padding-right: 10px;
	background-color: #C3CCD5;
	color: #fff;
	text-align: right;
}

.switch {
	display: block;
	width: 28px;
	height: 28px;
	margin: 2px;
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 20px;
	border-radius: 20px;
	border: 0.5px solid rgba(0, 0, 0, 0.04);
	transition: 0.2s;
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
}

.checkbox:checked+.label .inner {
	margin-left: 0;
}

.checkbox:checked+.label .switch {
	right: 0px;
}

.title[placeholder]:empty:before {
	content: attr(placeholder);
	font-size: 16px;
	font-weight: 500;
}


.caption[placeholder]:empty:before {
	content: attr(placeholder);
	font-size: 14px;
	font-weight: 400;
}

.captionModal::placeholder {
	color: #93A0AE;
	font-size: 14px;
	font-weight: 400;
}

.title::placeholder {
	color: #93A0AE;
	font-size: 16px;
	font-weight: 500;
}

.slk::placeholder {
	color: #ffffff;
}

.tap::placeholder {
	color: #ffffff;
	opacity: 0.9;
	text-shadow: 0px 2px 4px rgba(35, 47, 46, 0.25);
}

.comment[placeholder]:empty:before {
	content: attr(placeholder);
}


.comment:focus[placeholder]:empty:before {
	color: #748290
}

.comment:not(:focus):not(:empty) {
	color: #FFFFFF;
}



.tap[placeholder]:empty:before {
	content: attr(placeholder);
	color: #ffffff;
	opacity: 0.9;
	text-shadow: 0px 2px 4px rgba(35, 47, 46, 0.25);
}

.mentionTag[placeholder]:empty:before {
	content: attr(placeholder);
	color: #ffffff;
	opacity: 0.9;
	font-size: 36px;
	text-shadow: 0px 2px 4px rgba(35, 47, 46, 0.25);
}

.bio[placeholder]:empty {
	text-align: left;
}

.bio[placeholder]:empty:before {
	content: attr(placeholder);
	color: #ffffff;
	opacity: 0.9;
	font-size: 14px;
	text-shadow: 0px 2px 4px rgba(35, 47, 46, 0.25);
}

.no-scroll::-webkit-scrollbar {
	display: none;
}

.mention {
	color: #95F4EA;
}

.cc-mention {
	display: inline-block;
	position: relative;
}

.cc-mentions__underline {
	position: absolute;
	height: 2px;
	background-color: #fff;
	left: 32px;
	right: 0;
	bottom: 0px;
}

.ecr-dismiss .ecr-dismiss-quickfade {
	opacity: 0;
}

.ecr-dismiss-quickfade {
	opacity: 1;
	transition-property: opacity;
	transition-timing-function: ease-in-out;
	transition-duration: 150ms;
}

@font-face {
	font-family: 'inter';
	src:
		local('inter-variableFont'), url(./fonts/Inter-Regular.ttf) format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'inter';
	src:
		local('inter-variableFont'), url(./fonts/Inter-Medium.ttf) format('truetype');
	font-weight: 500;
}

@font-face {
	font-family: 'inter';
	src:
		local('inter-variableFont'), url(./fonts/Inter-SemiBold.ttf) format('truetype');
	font-weight: 600;
}

@keyframes skeleton {
	0% {
		background-position: 380% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

@keyframes load {
	0% {
		transform: rotate(-10deg);
	}

	50% {
		transform: rotate(10deg);
	}

	100% {
		transform: rotate(-10deg);
	}
}

@keyframes walk {
	0% {
		left: 0;
	}

	98% {
		left: calc(100vw - 10px)
	}

	100% {
		left: 140vw;
	}
}