.experience_tray_container {
	position: relative;
	pointer-events: none;
	/* position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1000; */
}

.experience_tray {
	user-select: none;
	/* position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex; */
	overflow: visible;
	overflow-Y: hidden;
	/* z-index: 1000; */
}

.experience_tray_inner::-webkit-scrollbar,
.experience_tray::-webkit-scrollbar {
	display: none;
}

.experience_tray_inner {
	position: absolute;
	pointer-events: all;
	/* top: 52px;
	left: 0;
	right: 0; */
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 6px;
	padding-right: calc(50vw - 48px);
	display: flex;
	align-items: flex-end;
	flex-wrap: nowrap;
	scrollbar-width: none;
	overflow-x: auto;
	overscroll-behavior: none;
	overflow-y: hidden;
}

.experience_tray_gradient {
	/* position: absolute;
	top: 0;
	left: 0;
	bottom: 60px;
	right: 0;
	pointer-events: none;
	z-index: -1;
	background: linear-gradient(180deg, rgba(35, 47, 46, 0) -4.69%, rgba(35, 47, 46, 0.1) 9.47%, rgba(35, 47, 46, 0.2) 23.7%, rgba(35, 47, 46, 0.3) 37.79%, rgba(35, 47, 46, 0.4) 52%, rgba(35, 47, 46, 0.5) 66.11%, rgba(35, 47, 46, 0.6) 80.29%, rgba(35, 47, 46, 0.7) 94.43%, rgba(35, 47, 46, 0.8) 100%); */
}

.experience_tray_element {
	position: relative;
	display: inline-block;
	height: 140px;
	width: 80px;
	margin-left: 6px;
	margin-right: 6px;
	user-select: none;
	flex: 0 0 auto;
	transition: opacity linear 0.2s;
	/* filter: drop-shadow(0px 1px 3px rgba(50, 56, 62, 0.25)), */
}

.selected .experience_tray_element_image {
	box-shadow: inset 0 0 0 2px #EEF1F7;
}

.experience_tray_element_image {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 2px;
	box-shadow: inset 0 0 0 1px #93A0AE;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: #1B242D;
	background-image: linear-gradient(90deg, rgba(51, 59, 67, 0) 0%, #333B43 100%);
}

.experience_tray_dark_overlay {
	position: absolute;
	touch-action: none;
	pointer-events: none;
	opacity: 0;
	background-color: #1B242D;
	z-index: 1;
	top: 0;
	left: 8px;
	right: 8px;
	bottom: 0;
}

.tray_drag.experience_tray_element {
	border-width: 0;
}

.tray_drag.experience_tray_element.ghost {
	border-width: 2px;
}

.tray_drag .experience_tray_delete {
	display: none;
}

.experience_tray_delete {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 34px;
	height: 34px;
	padding-bottom: 6px;
	padding-left: 6px;
}

.experience_tray_delete_icon_container {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #EEF1F7;
	border-radius: 100%;
	width: 18px;
	height: 18px;
	pointer-events: none;
}

.experience_tray_delete_icon {
	display: inline-block;
	width: 10px;
	height: 10px;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.75 1.25L1.25 8.75M1.25 1.25L8.75 8.75' stroke='%231B242D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
	pointer-events: none;
}

.experience_tray_play {
	position: absolute;
	bottom: 8px;
	right: 15px;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 12px;
	height: 12px;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(../../../../../rds/components/IconV3/icons/PlayFilled.svg);
}


.experience_tray_plus_container {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 2px;
	box-shadow: inset 0 0 0 1px #EEF1F7;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.experience_tray_plus {
	position: absolute;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(../../../../../rds/components/IconV3/icons/Plus.svg);
}

.experience_tray_media {
	position: absolute;
	z-index: 2;
	display: none;
	align-items: center;
	justify-content: center;
	left: calc(50% - 8px);
	top: calc(50% - 8px);
	width: 16px;
	height: 16px;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(../../../../../rds/components/IconV3/icons/PhotoLibrary.svg);
}

.experience_tray_empty .experience_tray_media {
	display: flex;
}

.experience_tray_empty .experience_tray_element_image {
	box-shadow: none;
}